import React, { Component, createRef } from 'react'
import * as Constants from '../constants'
import * as util from '../lib/util'

import NavBar from '../lib/components/NavBar'
import TitleScreen from '../lib/components/screens/TitleScreen'
import TextScreen from '../lib/components/screens/TextScreen'
import Map from '../lib/components/Map'
//import OrganizerScreen from '../lib/components/screens/OrganizerScreen'
import GalleryScreen from '../lib/components/screens/GalleryScreen'
import { RegistrationForm, Conditions } from '../lib/components/RegistrationForm'
import PresentationGroup from '../lib/components/PresentationGroup'
import OrganizerGroup from '../lib/components/OrganizerGroup'

import { initializeApp } from 'firebase/app'
import { getDatabase, get, child, ref as dbRef } from 'firebase/database'
import { fireapp_settings } from '../constants'
//import { unpackPresentations } from '../lib/util'

import './EclipseStyles/main.scss'
import './EclipseStyles/ddlc.scss'
import { Spinner } from '@vechaiui/react' // eslint-disable-line
import SharedContext from '../lib/sharedContext'

// temporary variables for testing
const enableRegistration = false

const SlowText = (props) => {
    if (props.error) return props.errorChildren || 'Ah... Nastala chyba...'
    if (props.value) return props.children
    else return (<Spinner size='xs'/>)
}

export default class Eclipse extends Component{
    constructor(props){
        super(props)
        this.points = {
            home: { title: 'Home', ref: createRef(), position: 0 },
            about: { title: 'O akci', ref: createRef() },
            presentations: { title: 'Přednášky', ref: createRef() },
            organizers: { title: 'Organizátoři', ref: createRef() },
            gallery: { title: 'Galerie', ref: createRef() },
            registration: { title: 'Registrace', ref: createRef() },
        }
        this.scrollTo = this.scrollTo.bind(this)
        this.scrollInfo = {
            target: 0,
            frameRate: 1000/60, // execute every ## seconds,
            interval: null,
            bias: 8,
            lastValue: 0
        }

        this.changeEndpoint = this.changeEndpoint.bind(this)
        this.locationIsUpdated = this.locationIsUpdated.bind(this)
        this.ddlc = (Math.random() > 0.9 && (new Date()).getHours() < 3) ? 'ddlc' : ''

        this.state = {
            loading: true,
            presentations: {
                main: [],
                student: []
            },
            organizers: {
                nothidden: []
            }
        }
    }

    scrollTo(offset){
        let lowerBorder = document.body.scrollHeight - window.innerHeight
        this.scrollInfo.target = Math.min(offset, lowerBorder)

        if(this.scrollInfo.interval === null)
        this.scrollInfo.interval = setInterval(() => {
            let { target, bias } = this.scrollInfo
            if(Math.abs(window.pageYOffset - target) >= bias){
                let position = util.lerp(window.pageYOffset, target, .6)
                window.scrollTo(0, position)

                if(this.scrollInfo.lastValue === position){ //failsafe in case the numbers keep looping (eg out of bounds)
                    window.scrollTo(0, this.scrollInfo.target)
                    clearInterval(this.scrollInfo.interval)
                    this.scrollInfo.interval = null
                    return
                }
                this.scrollInfo.lastValue = position
            }else{
                window.scrollTo(0, this.scrollInfo.target)
                clearInterval(this.scrollInfo.interval)
                this.scrollInfo.interval = null
            }
        }, this.scrollInfo.frameRate)
    }

    render(){
        return (
            <SharedContext.Provider value={{data: this.state.data, setData: null}}>
                <div className={ ['whiteText', this.ddlc].join(' ') }>
                    <NavBar buttons={this.points} scrollFunc={this.scrollTo} change={this.changeEndpoint}/>

                    <TitleScreen change={this.changeEndpoint}/>

                    <TextScreen ref={this.points.about.ref}>
                                        {
                                            (() => {
                                                if(!this.state?.data) return null

                                                const about = Constants.text.about.split('___').map(field => {
                                                    switch(field){
                                                        case 'DATE_VENUE':
                                                            return util.czechDateButInCorrectFall(this.state.data?.date_venue)
                                                        case 'TIME':
                                                            return this.state.data?.date_venue.split('T').pop()
                                                        case 'DATE_SPEAKER':
                                                            return util.czechDateButInCorrectFall(this.state.data?.date_speaker)
                                                        case 'DATE_REGISTER':
                                                            return util.czechDateButInCorrectFall(this.state.data?.date_normal)
                                                        default:
                                                            return field
                                                    }
                                                }).join('')
                                                const nextabout = Constants.text.nextabout.split('___').map(field => {
                                                    switch(field){
                                                        case 'YEAR':
                                                            return this.state.data?.year
                                                        default:
                                                            return field
                                                    }
                                                }).join('')

                                                switch(this.state?.data.registration_state){
                                                    case 'OPEN':
                                                        return [about]
                                                    case 'CLOSED':
                                                        return [about]
                                                    case 'CLOSED_PRES':
                                                        return [about]
                                                    case 'CLOSED_EARLY':
                                                        return [about]
                                                    case 'ONE_LINK':
                                                        return [about]
                                                    case 'THANKS':
                                                        return [nextabout]
                                                    default:
                                                        return [about]
                                                }
                                            })()
                                        }
                        <Map/>
                    </TextScreen>

                    <TextScreen ref={this.points.presentations.ref} background="#161626">
                        {Constants.text.mainPresentations}
                        { <SlowText value={!this.state.loading} error={this.state.error}>
                            <PresentationGroup source={this.state.presentations.main}/>
                        </SlowText> }
                        {'# Další přednášky'}
                        { <SlowText value={!this.state.loading} error={this.state.error}>
                            { (() => {
                                if (this.state.presentations.student.length === 0) return 'Postupně budou přidávány nové přednášky. Pokud chcete přednášet, zaregistrujte se níže.'
                                return (<PresentationGroup tiny source={this.state.presentations.student}/>)
                            })() }
                        </SlowText> }
                    </TextScreen>

                    <TextScreen ref={this.points.organizers.ref} background="#202030">
                        {'# Organizátoři'}
                        { <SlowText value={!this.state.loading} error={this.state.error}>
                            { (() => {
                                if (this.state.organizers.nothidden.length === 0) return 'Organizátoři budou postupně zveřejňováni.'
                                return (<OrganizerGroup tiny source={this.state.organizers.nothidden}/>)
                            })() }
                        </SlowText> }
                        {Constants.text.orgDisclaimer}
                    </TextScreen>

                    <TextScreen source={
                        Constants.text.entryFee.split('___').map(field => {
                            switch(field){
                                case 'PRICE_LATE':
                                    return this.state.data?.price_late
                                case 'PRICE_NORMAL':
                                    return this.state.data?.price_normal
                                default:
                                    return field
                            }
                        }).join('')
                    }/>
        
                    <GalleryScreen ref={this.points.gallery.ref}/>
                    <TextScreen ref={this.points.organizers.ref} background="#1b2335">
                        {Constants.text.galleryLink}
                    </TextScreen>

                    <TextScreen ref={this.points.registration.ref} background="#0e0e1f">
                        {'# Registrace'}
                        {/* todo: make this changable on the go, maybe change class name */}
                        {<div className="buttcheek">
                            { (enableRegistration || window.location.hash.substring(1) === 'betaWah' ) ? <>
                                <RegistrationForm/>
                                <Conditions url={this.state.data?.underage_url}/>
                            </> : <div style={{width:'100%'}} className='buttcheek'>
                                <div style={{display: 'flex', flexDirection: 'column'}}>                                    
                                    <SlowText value={this.state.data}>
                                        {
                                            (() => {
                                                if(!this.state?.data) return null

                                                const btnRegister = this.state.data.registration_url ? <button key='btn_register' onClick={e => window.open(this.state.data.registration_url)} className='lastMinuteButton'>Registrace pro návštěvníky</button> : null
                                                const btnSpeakerRegister = this.state.data.registration_speaker_url ? <button key='btn_register_for_speaker' onClick={e => window.open(this.state.data.registration_speaker_url)} className='lastMinuteButton'>Registrace pro přednášející</button> : null
                                                const btnOneLink = this.state.data.registration_url ? <button key='btn_onelink' onClick={e => window.open(this.state.data.registration_url)} className='lastMinuteButton'>Registrace pro všechny</button> : null

                                                switch(this.state?.data.registration_state){
                                                    case 'OPEN':
                                                        return [btnRegister, btnSpeakerRegister]
                                                    case 'CLOSED':
                                                        return (<span className='aah'>Registrace byla uzavřena.</span>)
                                                    case 'CLOSED_PRES':
                                                        return [btnRegister]
                                                    case 'CLOSED_EARLY':
                                                        return 'Registrace pro další ročník bude spuštěna koncem léta.'
                                                    case 'CANCELLED':
                                                        return 'Akce byla zrušena.'
                                                    case 'COVID':
                                                        return 'Akce byla zrušena kvůli COVIDu.'
                                                    case 'ONE_LINK':
                                                        return [btnOneLink]
                                                    case 'THANKS':
                                                        return 'Registrace pro další ročník bude spuštěna koncem léta.'
                                                    default:
                                                        return `Wah! ${this.state.data.registration_state}`
                                                }
                                            })()
                                        }
                                        <span className='aah'>
                                            Odesláním registrace souhlasíte se
                                            <a rel='noopener noreferrer' href={this.state.data?.tos_url} target='_blank'> zpracováním osobních údajů.</a>
                                        </span>
                                        <span className='aah'>
                                            Účastí na akci souhlasíte s
                                            <a rel='noopener noreferrer' href={this.state.data?.rules_url} target='_blank'> pravidly akce.</a>
                                        </span>
                                        <span className='aah'>
                                            Potíže s registrací a webem hlaste na mailu
                                            <a rel='noopener noreferrer' href={'mailto:'+this.state.data?.admin_mail} target='_blank'> {this.state.data?.admin_mail}</a>
                                        </span>
                                    </SlowText>
                                </div>
                                <Conditions url={this.state.data?.underage_url}/>
                            </div> }
                        </div>}
                    </TextScreen>
                </div>
            </SharedContext.Provider>
        )
    }

    componentDidMount(){
		window.addEventListener('popstate', this.locationIsUpdated)
		let url = window.location.hash.substring(1)
        this.setState({url})
        let point = this.points[url]
        if(point && point.ref.current) this.scrollTo(point.ref.current.offsetTop - 32)

        const fireapp = initializeApp(fireapp_settings)
        const database = getDatabase(fireapp)

        get(child(dbRef(database), 'global')).then(snapshot => {
            this.setState({ ...this.state, data: snapshot.val() })
        })

        get(child(dbRef(database), 'presentations')).then(snapshot => {
            const presentations = Object.values(snapshot.val())
            this.setState({ ...this.state, loading: false, presentations: {
                main: presentations.filter(x => !x.hidden && !x.only_harmony && x.type === 'MAIN'),
                student: presentations.filter(x => !x.hidden && !x.only_harmony && x.type === 'STUDENT')
            } })
            // const presentations = unpackPresentations(snapshot.val())
            // this.setState({
            //     loading: false,
            //     presentations: { // oh boy dual loop even if it could be one, how sinful of me
            //         main: presentations.filter(x => x.type === 'main'),
            //         student: presentations.filter(x => x.type === 'student')
            //     }
            // })
            // console.log(presentations)

        }).catch(err => {
            console.error(err)
            this.setState({
                loading: false,
                error: err
            })
        })

        get(child(dbRef(database), 'organizers')).then(snapshot => {
            const organizers = Object.values(snapshot.val())
            this.setState({ ...this.state, loading: false, organizers: {
                nothidden: organizers.filter(x => !x.hidden && x.type === 'ORGANIZER')
            } })

        }).catch(err => {
            console.error(err)
            this.setState({
                loading: false,
                error: err
            })
        })
	}

    componentWillUnmount(){
        window.removeEventListener('popstate', this.locationIsUpdated)
    }

    locationIsUpdated(){
        this.setState({ url: window.location.hash.substring(1) })
    }

	changeEndpoint(url){
		window.history.pushState(null, null, `#${url}`)
        this.setState({url})

        let point = this.points[url]
        if(point && point.ref.current) this.scrollTo(point.ref.current.offsetTop - 32)
        else this.scrollTo(0)
	}
}
